@function combineOverlays($overlay, $base) {
	@if not $overlay or not $base {
		@if not $overlay {
			@return $base;
		}
		@return $overlay;
	}
	$r0: red($overlay);
	$g0: green($overlay);
	$b0: blue($overlay);
	$a0: alpha($overlay);

	$r1: red($base);
	$g1: green($base);
	$b1: blue($base);
	$a1: alpha($base);

	$a01: (1 - $a0) * $a1 + $a0;
	$r: ((1 - $a0) * $a1 * $r1 + $a0 * $r0) / $a01;
	$g: ((1 - $a0) * $a1 * $g1 + $a0 * $g0) / $a01;
	$b: ((1 - $a0) * $a1 * $b1 + $a0 * $b0) / $a01;

	@return rgba($r, $g, $b, $a01);
}
